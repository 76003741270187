<template>
  <div ref="giftSendWindow" class="window-container">
    <div class="left-window">
      <div class="menus">
        <template v-for="(item, index) in header">
          <div
            class="menu-item"
            :class="
              currentSection === item.value
                ? item.value === 'statistics'
                  ? 'profile-sprite tab1'
                  : 'profile-sprite tab2'
                : ''
            "
            :key="`menu-item${index}`"
            @click="currentSection = item.value"
          >
            <span class="menu-text" :key="`menu-text${index}`">{{
              item.text
            }}</span>
            <div class="slot-line" v-if="currentSection === item.value"></div>
            <div
              :class="currentSection !== item.value ? 'right-line' : ''"
            ></div>
          </div>
        </template>
      </div>
      <div class="close-button flex-center" @click="closeWindow">
        <span class="sprite-table-pop-up exit-icon"></span>
      </div>
      <!-- <div class="gift-header" >
        <div
          class="gift-header-item"
          v-for="(item, index) in header"
          :key="index"
        >
          {{ item.text }}
        </div>
      </div> -->
      <div class="gift-window" v-if="currentSection === 'gift'">
        <template v-for="(gift, index) in 20">
          <div
            :key="index"
            class="gift-item flex-center"
            @click="selectedGift = index"
          >
            <div
              class="sprite-gifts flex-center"
              :class="`gift-${index}`"
            ></div>
            <span class="gift-item-price">
              {{ getShortChipFormat(getActiveRoomState.bet / 10) }}
            </span>
            <div class="ok-icon" v-if="index === selectedGift"></div>
          </div>
        </template>
        <div class="gift-window-action">
          <div
            class="gift-window-action-sell ui-button ui-button-small-orange"
            @click="sendItem(false)"
          >
            {{
              giftSendIsMe
                ? $t("giftsWindow.getYourself")
                : $t("giftsWindow.getGift")
            }}
          </div>
          <div
            class="gift-window-action-all-sell ui-button ui-button-small-orange"
            @click="sendItem(true)"
          >
            {{ $t("giftsWindow.getEveryone") }}
          </div>
        </div>
      </div>

      <div class="expression-window" v-if="currentSection === 'expression'">
        <div
          class="expression-window-item"
          v-for="(item, index) in expressions"
          :key="index"
          @click="selectedExpression = item"
        >
          <div class="sprite-expression" :class="item"></div>
          <span class="expression-window-item-price">
            {{ getShortChipFormat(getActiveRoomState.bet / 5) }}
          </span>
          <div
            class="expression-ok-icon"
            v-if="item === selectedExpression"
          ></div>
        </div>
        <div class="expression-window-action">
          <div
            class="expression-window-action-sell ui-button ui-button-small-orange"
            @click="sendItem(false)"
          >
            {{
              giftSendIsMe
                ? $t("giftsWindow.getYourself")
                : $t("giftsWindow.getGift")
            }}
          </div>
          <div
            class="expression-window-action-all-sell ui-button ui-button-small-orange"
            @click="sendItem(true)"
          >
            {{ $t("giftsWindow.getEveryone") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Avatar from "@/components/Misc/Avatar";
import Helpers from "@/mixin/Helpers";
import clipboard from "@/components/Misc/Svg/Clipboard";
import ConfirmWindow from "@/components/Misc/GlobalWindows/ConfirmWindow";

export default {
  name: "SendGiftWindow",
  mixins: [Helpers],
  components: {
    Avatar,
    clipboard,
    Card: () => import("@/components/Misc/PokerTable/Cards/Card"),
    ConfirmWindow,
  },
  data(vm) {
    return {
      giftSendPlayerId: -1,
      selectedGift: -1,
      selectedExpression: "",
      currentSection: "expression",
      header: [
        { value: "expression", text: vm.$t("giftsWindow.expression") },
        { value: "gift", text: vm.$t("giftsWindow.gift") },
      ],
      expressions: [
        "bira",
        "konfeti",
        "salatalik",
        "bomba",
        "yumurta",
        "kalp",
        "opucuk",
        "aglama",
        "gul",
        "tokat",
        "domates",
        "silah",
      ],
    };
  },
  watch: {
    "$store.state.modals.sendGiftWindow": {
      handler(val) {
        if (val) {
          this.initialize();
        }
      },
    },
    nAmISitting(val) {
      if (!val) {
        this.closeWindow();
      }
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["getChipFormat", "getShortChipFormat"]),
    ...mapGetters({
      nAmISitting: "service/nAmISitting",
      getActiveRoomState: "service/getActiveRoomState",
    }),
    ...mapGetters("user", ["getLevel"]),
    giftSendIsMe() {
      return this.user.id === this.$store.state.gift.sendGiftPlayerId;
    },
  },
  methods: {
    sendItem(allSend = false) {
      if (!this.nAmISitting) {
        this.$plugins.toast.show({
          text: this.$t("giftsWindow.sitATable"),
          duration: 2500,
        });
        return;
      }

      let toPlayers = [];

      if (!allSend) {
        toPlayers.push(this.$store.state.gift.sendGiftPlayerId);
      } else {
        if (
          this.$store.state.service.ACTIVE_ROOM &&
          this.$store.state.service.ACTIVE_ROOM.players.length > 0
        ) {
          const players = this.$store.state.service.ACTIVE_ROOM.players.filter(
            (player) =>
              player.id !== this.$store.state.user.id && player.position > -1
          );
          if (players.length > 0) {
            players.forEach((player) => {
              toPlayers.push(player.id);
            });

            console.log("this.giftSendIsMe", this.giftSendIsMe);
            if (this.giftSendIsMe) {
              toPlayers.push(this.$store.state.gift.sendGiftPlayerId);
            }
          } else {
            this.$plugins.toast.show({
              text: this.$t("others.tableInFriendNotFound"),
              duration: 2500,
            });
            return;
          }
        }
      }
      console.log("toPlayers", toPlayers);
      //Is the chip enough?
      if (this.currentSection === "gift") {
        if (
          this.$store.state.user.chip <
          (Number(this.$store.state.service.ACTIVE_ROOM.bet) / 10) *
            toPlayers.length
        ) {
          this.$plugins.toast.show({
            text: this.$t("errors.insufficientBalance"),
            duration: 2500,
          });
          return;
        }
      } else if (this.currentSection === "expression") {
        if (
          this.$store.state.user.chip <
          (Number(this.$store.state.service.ACTIVE_ROOM.bet) / 5) *
            toPlayers.length
        ) {
          this.$plugins.toast.show({
            text: this.$t("errors.insufficientBalance"),
            duration: 2500,
          });
          return;
        }
      }

      if (this.currentSection === "gift") {
        if (this.selectedGift < 0) {
          this.$plugins.toast.show({
            text: this.$t("others.requiredSelectedGift"),
            duration: 2500,
          });
          return;
        }

        this.$store.dispatch("gift/sendAnimation", {
          type: "animation",
          id: `gift-${this.selectedGift}`,
          price: Number(this.$store.state.service.ACTIVE_ROOM.bet) / 10,
          to: toPlayers,
          roomId: this.$store.state.service.ACTIVE_ROOM.id,
        });
      } else if (this.currentSection === "expression") {
        if (!this.selectedExpression) {
          this.$plugins.toast.show({
            text: this.$t("others.requiredSelectedGift"),
            duration: 2500,
          });
          return;
        }

        this.$store.dispatch("gift/sendAnimation", {
          type: "animation",
          id: this.selectedExpression,
          price: Number(this.$store.state.service.ACTIVE_ROOM.bet) / 5,
          to: toPlayers,
          roomId: this.$store.state.service.ACTIVE_ROOM.id,
        });
      }
      this.closeWindow();
    },
    closeWindow() {
      this.$refs.giftSendWindow.classList.add("closeAnimate");

      setTimeout(() => {
        this.$store.commit("modals/hide", "sendGiftWindow");
        this.$store.commit("friends/clearSelectedUserModal");
        this.$store.state.gift.sendGiftPlayerId = -1;
      }, 700);
    },
    handleTabs(tab) {
      this.$data.profileCurrentTab = tab;
    },
    initialize() {
      if (this.$store.state.gift.sendGiftPlayerId > -1) {
        this.giftSendPlayerId = this.$store.state.gift.sendGiftPlayerId;
      } else {
        this.$plugins.toast.show({
          text: this.$t("confirmWindow.userNotFound"),
          duration: 2500,
        });
        // this.$store.commit("modals/hide", "sendGiftWindow");
      }
      this.selectedGift = -1;
      this.selectedExpression = "";
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style lang="scss" scoped>
.window-container {
  position: fixed;
  top: 12%;
  left: 0%;
  height: 76%;
  width: 40%;

  z-index: 20;
  animation-name: openAnimation;
  animation-duration: 0.7s;
  .left-window {
    height: 100%;
    width: 100%;
    overflow: hidden;
    .gift-window {
      position: relative;
      padding-left: 320px;
      height: 91%;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: flex-end;
      background: rgba(63, 16, 22, 0.95);
      .gift-item {
        min-width: 150px;
        min-height: 160px;
        margin: 0px 10px 0px 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        .sprite-gifts {
          transform: scale(1.5);
        }
        .active {
          background: rgb(#711a23, 1);
        }
        .ok-icon {
          position: absolute;
          height: 80%;
          width: 90%;
          background-size: cover;
          top: -5px;
          color: #fff;
          background-color: rgba(87, 255, 137, 0.15);
          border-radius: 100%;
        }
        &-price {
          font-size: 34px;
          color: #ffffff;
          text-shadow: 0 3px 5px #000000;
          position: relative;
          top: 25px;
        }
      }
    }
    .gift-window::-webkit-scrollbar-thumb {
      background: none !important;
    }
    .gift-window-action {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      font-size: 36px;
      text-shadow: 0 3px 5px #000000;
      background: rgba(37, 6, 10, 0.95);
      color: #ffffff;
      padding: 10px;
    }
    .expression-window {
      position: relative;
      padding-left: 320px;
      height: 91%;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: flex-end;
      background: rgba(63, 16, 22, 0.95);
      &-item {
        min-width: 200px;
        min-height: 160px;
        margin: 0px 10px 0px 10px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .sprite-expression {
          transform: scale(1.4);
        }

        &-price {
          font-size: 34px;
          color: #ffffff;
          text-shadow: 0 3px 5px #000000;
          position: relative;
          top: 25px;
        }

        .expression-ok-icon {
          position: absolute;
          height: 100%;
          width: 100%;
          background-size: cover;
          color: #fff;
          background-color: rgba(87, 255, 137, 0.15);
          border-radius: 100%;
        }
      }
    }
    .expression-window-action {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      font-size: 36px;
      text-shadow: 0 3px 5px #000000;
      color: #ffffff;
      margin-bottom: 10px;
    }
    .close-button {
      position: absolute;
      z-index: 2;
      top: 10px;
      left: 950px;
      width: 58px;
      height: 58px;
      border-radius: 100%;
      border: 4px solid #cf707f;
      cursor: pointer;

      transition: all 0.25s ease;
      &:active {
        filter: brightness(60%);
        -webkit-filter: brightness(60%);
      }
      .exit-icon {
        transform: scale(0.7);
      }
    }
    .menus {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 8%;
      position: relative;
      padding-left: 300px;
      background: rgb(37 6 10 / 95%);

      .menu-item {
        position: relative;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 2;
        max-height: 87px;
        .slot-line {
          width: 90%;
          position: relative;
          top: 8px;
          left: 8px;
          z-index: 1;
        }
        .right-line {
          position: absolute;
          height: 50%;
          width: 2px;
          background: #902533;
          left: -5px;
        }
        .menu-text {
          font-size: 32px;
          color: #cf707f;
          text-shadow: rgba(0, 0, 0, 1) 0px 2px 1px;
          text-transform: uppercase;
          width: 100%;
          text-align: center;
          min-height: 52px;
          z-index: 1;
          position: relative;
          top: 10px;
        }
        .profile-sprite {
          position: relative;
          display: inline;
          z-index: 0;
          left: 1px;
          top: 0px;
        }
        &:first-child {
          .right-line {
            display: none;
          }
        }
      }
      .tab1,
      .tab2 {
        .menu-text {
          color: #ffffff;
        }
      }
      .tab1 {
        background-position: 1px -152px;
      }
      .tab2 {
        background-position: -280px -152px;
      }
    }
  }
}

.closeAnimate {
  animation-name: closeAnimation;
  animation-duration: 0.7s;
  animation-fill-mode: backwards;
}
@keyframes openAnimation {
  0% {
    transform: translateX(-500px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes closeAnimation {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-800px);
    display: none;
  }
}
</style>

<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="1000"
    height="600"
    :show="show"
    :globalClassName="'confirm-outer'"
    :titleText="titleText"
    :stopClickOutside="true"
  >
    <div class="container confirm-container">
      <div class="heading" v-if="defaultSure">
        {{ title ? title : $t("confirmWindow.sure") }}
      </div>
      <div class="heading" v-if="!defaultSure">
        {{ title ? title : $t("confirmWindow.DaysVip") }}
      </div>

      <div class="content" v-if="content">{{ content }}</div>
      <div class="buttons">
        <div class="ui-button ui-button-small-red" @click="closeWindow">
          {{ $t("general.no") }}
        </div>
        <div class="ui-button ui-button-small-green" @click="confirm">
          {{ $t("general.yes") }}
        </div>
      </div>
    </div>
  </BaseWindow>
</template>

<script>
import BaseWindow from "./BaseWindow";
export default {
  name: "ConfirmWindow",
  components: {
    BaseWindow,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    defaultSure: {
      default: true,
    },
    titleText: {
      type: [String, Boolean],
      required: false,
      default: "",
    },
    title: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    content: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    closeWindow() {
      this.$emit("closeConfirm");
    },
  },
};
</script>

<style scoped lang="scss">
.confirm-container {
  background: #83212d;
}
.container {
  width: 99%;
  height: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  .heading {
    font-size: 45px;
    font-weight: bold;
    height: 10%;
  }
  .buttons {
    width: 55%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
</style>
